import { SystemStyleObject } from '@styled-system/css';
import { SxStyleProp } from 'theme-ui';

export const pageContainerWidthOnDesktop = 1280;

export const pageContainerMargin: (usesDesignSystem?: boolean) => SystemStyleObject =
  (usesDesignSystem) =>
  ({ breakpointSizes }) =>
    usesDesignSystem
      ? [breakpointSizes.mobileMargin, breakpointSizes.tabletMargin, 'auto']
      : ['3xs', 'xs', 'auto'];

export const pageContainerStyles = (usesDesignSystem?: boolean): SxStyleProp => ({
  marginY: 0,
  marginX: pageContainerMargin(usesDesignSystem),
  maxWidth: ({ breakpointSizes }) => [
    null,
    null,
    `calc(100% - ${2 * breakpointSizes.laptopMargin}px)`,
  ],
  width: [null, null, pageContainerWidthOnDesktop],
});
